//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { i18n } from '@/vueI18n';
// import LoadingTripInfo from '@/modules/trip/components/partial-components/loading-trip-info.vue';

export default {
  name: 'app-trip-direction-on-map',
  props: {
    tripId: {
      type: String,
      required: false,
    },
  },

  components:{
    // [LoadingTripInfo.name]: LoadingTripInfo,
  },

  // async mounted() {
  //   await this.doFindByVuexfire(this.id);
  // },

  data() {
    return {
      center: { lat: 31.2229972, lng: 29.9450273 },
      markers: [
        {
          position: { lat: 31.2229972, lng: 29.9450273 },
        },
      ],
      // markerIcon: '/images/map/marker2.svg'
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
  },
  methods: {
    ...mapActions({
      doFind: 'trip/view/doFind',
      doFindByVuexfire: 'trip/view/doFindByVuexfire',
    }),
    i18n(key, args) {
      return i18n(key, args);
      // return this.$t(key, args);
    },
    async doFilter() {
      await this.resetCursor()
      await this.doFetchReviews({ tripId: this.tripId, limit: 10 })
    },
  },
};
