//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TripCancelationReason from '@/modules/trip/components/partial-components/trip-cancelation-reason.vue';
import TripDirectionOnMap from '@/modules/trip/components/partial-components/trip-direction-on-map.vue';
import TripInfo from '@/modules/trip/components/partial-components/trip-info.vue';
import CLientReservationList from '@/modules/trip/components/partial-components/client-reservation-list.vue';

import { mapGetters, mapActions } from 'vuex';
import vueI18n from '@/vueI18n'
import { i18n } from '@/vueI18n';
// import moment from 'moment';

// import Roles from '@/security/roles';
import { TripModel } from '@/modules/trip/trip-model';
const { fields } = TripModel;

// import firebase from 'firebase/compat/app';
// import 'firebase/compat/firestore'
// const db = firebase.firestore();

export default {
  name: 'app-trip-view-page',
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  
  components: {
    [TripCancelationReason.name]: TripCancelationReason,
    [TripDirectionOnMap.name]: TripDirectionOnMap,
    [TripInfo.name]: TripInfo,
    [CLientReservationList.name]: CLientReservationList,
  },
  
  // async mounted() {
  //   await this.doFindByVuexfire(this.id);
  // },

  data() {
    return {
      dialog: false,
      model: null,
      totalRating: 0,
      totalAmount: 0,
      fullName: undefined,
    };
  },
  computed: {
    ...mapGetters({
      record: 'trip/view/record',
      loading: 'trip/view/loading',

      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    fields() {
      return fields;
    },
    language() {
      return vueI18n.locale
    },
  },
  // firestore() {
  //   return {
  //     record: db.collection('user').doc(this.id),
  //   }
  // },
  watch: {
    record(val) {
      if (val) {
        this.fullName = this.record.fullName
        this.totalRating = this.record.rate
        this.totalAmount = this.record.wallet
      }
    }
  },
  methods: {
    ...mapActions({
      doFind: 'trip/view/doFind',
      doFindByVuexfire: 'trip/view/doFindByVuexfire',
      doToggleUserAccountStatus: 'iam/view/doToggleUserAccountStatus'
    }),
    i18n(key, args) {
      // return this.$t(key, args);
      return i18n(key, args);
    },
  },
};
