//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { i18n } from '@/vueI18n';

export default {
  name: 'loading-trip-info',

  props: {
    loading_section: {
      type: String,
      required: true,
    }
  },

  methods: {
    i18n(key, args) {
      return i18n(key, args);
    },
  },
};
