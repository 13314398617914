import { render, staticRenderFns } from "./trip-cancelation-reason.vue?vue&type=template&id=48b3a0e2&scoped=true&"
import script from "./trip-cancelation-reason.vue?vue&type=script&lang=js&"
export * from "./trip-cancelation-reason.vue?vue&type=script&lang=js&"
import style0 from "./trip-cancelation-reason.vue?vue&type=style&index=0&id=48b3a0e2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48b3a0e2",
  null
  
)

export default component.exports
import {QCard,QItemSection,QItemLabel,QItem} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCard,QItemSection,QItemLabel,QItem})
