//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { i18n } from '@/vueI18n';
import moment from 'moment';
import _ from 'lodash';

import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore'

export default {
  name: 'app-client-reservation-card',

  props: {
    value: {
      type: Object,
      required: true,
    } 
  },
  firestore() {
    return {
      client: firebase.firestore().collection('user').doc(this.value.clientId),
    }
  },

  data() {
    return {
      dialogVisible: false,
      modalVisible: false,
      model: null,
      client: undefined,
      dialog: false,
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    clientAvatar() {
      const defaultAvatar = '/images/avatars/user-avatar.svg'
      let avatar = null
      if (this.client) {
        if (this.client.avatar) {
          if (_.isString(this.client.avatar)) {
            avatar = this.client.avatar
          } else if (_.isObject(this.client.avatar)) {
            avatar = this.client.avatar?.publicUrl ?? defaultAvatar
          }
        } 
        else if (this.client.avatars && this.client.avatars.length) {
          if (_.isString(this.client.avatars[0])) {
            avatar = this.client.avatars?.[0] ?? defaultAvatar
          } else if (_.isObject(this.client.avatars[0])) {
            avatar = this.client.avatars?.[0]?.publicUrl ?? defaultAvatar
          }
        } 
      }
      
      return avatar && avatar !== "" ? avatar : defaultAvatar
    },
  },
  methods: {
    ...mapActions({

    }),
    i18n(code, args) {
      return i18n(code, args);
    },
    presenterDate(val) {
      return moment(val).locale(this.currentLanguageCode).format("D MMM, YYYY"); 
    },
  },
};
