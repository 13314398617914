import { render, staticRenderFns } from "./trip-info.vue?vue&type=template&id=38349355&scoped=true&"
import script from "./trip-info.vue?vue&type=script&lang=js&"
export * from "./trip-info.vue?vue&type=script&lang=js&"
import style0 from "./trip-info.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./trip-info.vue?vue&type=style&index=1&id=38349355&lang=scss&scoped=true&"
import style2 from "./trip-info.vue?vue&type=style&index=2&id=38349355&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38349355",
  null
  
)

export default component.exports
import {QSkeleton,QAvatar,QImg,QTimeline,QTimelineEntry,QSeparator,QSelect,QItem,QItemSection,QField,QIcon} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QSkeleton,QAvatar,QImg,QTimeline,QTimelineEntry,QSeparator,QSelect,QItem,QItemSection,QField,QIcon})
