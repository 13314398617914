//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { i18n } from '@/vueI18n';

import GuideCard from '@/modules/trip/components/partial-components/guide-card.vue';
import LoadingGuideCard from '@/modules/trip/components/partial-components/loading/loading-guide-card.vue';

export default {
  name: 'app-guide-list-card',
  // props: {
  //   guidesId: {
  //     type: Array,
  //     required: false,
  //     default: new Array(),
  //   },
  // },

  components: {
    [GuideCard.name]: GuideCard,
    [LoadingGuideCard.name]: LoadingGuideCard,
  },

  // mounted() {
  //   this.doFilter();
  // },

  data() {
    return {
      
    }
  },
  computed: {
    ...mapGetters({
      rows: 'guide/list/rows',
      loading: 'guide/list/loading',

      tripRecord: 'trip/view/record',
      tripLoading: 'trip/view/loading',

      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
  },
  methods: {
    ...mapActions({
      // resetCursor: 'guide/list/resetCursor',
      // setLoadMoreLast: 'guide/list/setLoadMoreLast',
      // doFetchGuides: 'guide/list/doFetchGuides',
      // doFetchMoreGuides: 'guide/list/doFetchMoreGuides',
    }),
    i18n(key, args) {
      return i18n(key, args);
    },
    // async doFilter() {
    //   await this.resetCursor()
    //   await this.doFetchGuides({ userId: this.userId, limit: 10 })
    // },
  },
};
