//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ClientReservationCard from '@/modules/trip/components/partial-components/client-reservation-card.vue';
import LoadingReservationCard from '@/modules/trip/components/partial-components/loading/loading-reservation-card.vue';

import { mapGetters, mapActions } from 'vuex';
import { i18n } from '@/vueI18n';
// import { Screen } from 'quasar'

export default {
  name: 'app-client-reservation-list',
  props: {
    tripId: {
      type: String,
      required: false,
    },
  },

  components: {
    [ClientReservationCard.name]: ClientReservationCard,
    [LoadingReservationCard.name]: LoadingReservationCard,
  },

  mounted() {
    this.doFilter();
  },

  data() {
    return {
      tab: 'income',
      rowsToBeDisplayed: [],
      reservations: [],
    }
  },
  computed: {
    ...mapGetters({
      rows: 'reservation/list/rows',
      loading: 'reservation/list/loading',
      moreRows: 'reservation/list/moreRows',

      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    loadingNum() {
      if (this.is_screen_sm || this.is_screen_xs || this.is_screen_md) return 1; 
      // if (this.is_screen_md) return 2; 
      // return 3
      return 2
    },
  },
  methods: {
    ...mapActions({
      resetCursor: 'reservation/list/resetCursor',
      setLoadMoreLast: 'reservation/list/setLoadMoreLast',
      doFetchReservations: 'reservation/list/doFetchReservations',
      doFetchMoreReservations: 'reservation/list/doFetchMoreReservations',
    }),
    i18n(key, args) {
      return i18n(key, args);
    },
    async doFilter() {
      await this.resetCursor()
      await this.doFetchReservations({ tripId: this.tripId, limit: 10 })
    },
    doFetchMore() {
      this.doFetchMoreReservations({ tripId: this.tripId, limit: 10 })
    },
    onLoad(index, done) {
      console.log('index = ', index);
      // if (index > 1) {
        setTimeout(() => {
          if (this.moreRows) {
            this.doFetchMore()
            done()
          }
        }, 2000)      
      // } else {
      //   setTimeout(() => {
      //     if (this.moreRows) {
      //       done()
      //     }
      //   }, 200)      
      // }
    },
    onLoadTransaction (index, done) {
      console.log('index = ', index);
      setTimeout(() => {
        for (let index = 0; index < this.loadingNum; index++) {
          this.reservations.push({
            id: `150${index}`,
            description: {
              en: 'Credit Added',
              ar: 'تمت إضافة رصيد',
            },
            payMethod: '',
            date: '2021-02-08T20:59:08.103Z',
            amount: 250,
            currency: 'SR',
            createdBy: 'cef94fbed27e7e4e7fb33a97084d2c1f',
            updatedBy: 'cef94fbed27e7e4e7fb33a97084d2c1f',
            createdAt: '2021-02-08T20:59:08.103Z',
            updatedAt: '2021-02-08T20:59:08.103Z',
          })
        }
        done()
      }, 2000)
    },
    
  },
};
