//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LoadingTripInfo from '@/modules/trip/components/partial-components/loading/loading-trip-info.vue';
import GuideListCard from '@/modules/trip/components/partial-components/guide-list-card.vue';

import { mapGetters, mapActions } from 'vuex';
import vueI18n from '@/vueI18n'
import { i18n } from '@/vueI18n';
import moment from 'moment';
import _ from 'lodash';

import { TripModel } from '@/modules/trip/trip-model';
const { fields } = TripModel;

// import firebase from 'firebase/compat/app';
// import 'firebase/compat/firestore'

export default {
  name: 'app-trip-info',
  props: {
    tripId: {
      type: String,
      required: false,
    },
  },

  components:{
    [LoadingTripInfo.name]: LoadingTripInfo,
    [GuideListCard.name]: GuideListCard,
  },

  async mounted() {
    await this.doFindByVuexfire(this.tripId);
    this.VIP_option = this.record.options?.[0]?.details ?? []
    this.doFindPlan(this.record?.planId)

    const collectionName = this.record?.ownerType == 'company' ? 'company' : 'user'
    const ownerId = this.record?.ownerType == 'company' ? this.record?.companyId : this.record?.createdBy
    this.doFindOwnerByVuexfire({ collectionName, id: ownerId })
  },
  // firestore() {
  //   return {
  //     plan: firebase.firestore().collection('plan').doc(this.record?.planId),
  //   }
  // },
  data() {
    return {
      dialog: false,
      VIP_option: [],
    }
  },
  computed: {
    ...mapGetters({
      record: 'trip/view/record',
      loading: 'trip/view/loading',
      
      plan: 'plan/view/record',
      loadingPlan: 'plan/view/loading',
      places: 'plan/view/places',

      loadingOwner: 'trip/view/loadingOwner',
      owner: 'trip/view/owner',

      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    fields() {
      return fields;
    },
    language() {
      return vueI18n.locale
    },
    options() {
      // return this.record?.options[this.vipIndex]?.details ?? []
      return this.record?.options.map(opt => {
        return {
          label: opt.price + ' ' + this.i18n('common.saudiCurrency'),
          value: opt.details
        }  
      })
    },
    ownerAvatar() {
      const defaultAvatar = '/images/avatars/user-avatar.svg'
      let avatar = null
      if (this.owner) {
        if (this.owner.avatar) {
          if (_.isString(this.owner.avatar)) {
            avatar = this.owner.avatar
          } else if (_.isObject(this.owner.avatar)) {
            avatar = this.owner.avatar?.publicUrl ?? defaultAvatar
          }
        } 
        else if (this.owner.avatars && this.owner.avatars.length) {
          if (_.isString(this.owner.avatars[0])) {
            avatar = this.owner.avatars?.[0] ?? defaultAvatar
          } else if (_.isObject(this.owner.avatars[0])) {
            avatar = this.owner.avatars?.[0]?.publicUrl ?? defaultAvatar
          }
        } 
        else if (this.owner.logo) {
          if (_.isString(this.owner.logo)) {
            avatar = this.owner.logo ?? defaultAvatar
          } else if (_.isObject(this.owner.logo)) {
            avatar = this.owner.logo?.publicUrl ?? defaultAvatar
          }
        }
      }
      
      return avatar && avatar !== "" ? avatar : defaultAvatar
    },
    ownerName() {
      return this.record?.ownerType == 'company' ? this.owner.name[this.currentLanguageCode] : this.owner.fullName
    },
    capacity() {
      const capacity = this.record?.capacity;
      return capacity ? (capacity?.min ?? 0) + ' - ' + (capacity?.max ?? 0) : 'ـــ';
    },
  },
  methods: {
    ...mapActions({
      doFind: 'trip/view/doFind',
      doFindByVuexfire: 'trip/view/doFindByVuexfire',
      doFindOwnerByVuexfire: 'trip/view/doFindOwnerByVuexfire',
      doFindPlan: 'plan/view/doFindByVuexfire',
    }),
    i18n(key, args) {
      return i18n(key, args);
    },
    presenter(row, fieldName) {
      const val = TripModel.presenter(row, fieldName);
      return val ? val : 'ـــ';
    },
    presenterEnum(row, fieldName) {
      const options = this.fields[fieldName].options;
      const val = options.filter(el => el.value == row[fieldName])
      return val.length ? val[0].label : 'ـــ';
    },
    presenterMap(row, fieldName) {
      const val = TripModel.presenter(row, fieldName);
      return val[this.currentLanguageCode] ? val[this.currentLanguageCode] : 'ـــ'; 
    },
    presenterDateTime(val) {
      // const val = TripModel.presenter(row, fieldName);
      // return val ? moment(val).locale(this.currentLanguageCode).format("dddd D MMM, YYYY h:mm A") : 'ـــ'; 
      return val ? moment(val).locale(this.currentLanguageCode).format("dddd D MMM, YYYY") + this.i18n('common.at') + moment(val).locale(this.currentLanguageCode).format("hh:mm A") : 'ـــ';
    },
    presenterDate(val) {
      // const val = TripModel.presenter(row, fieldName)
      return val ? moment(val).locale(this.currentLanguageCode).format("D MMM, YYYY") : 'ـــــــ'; 
    },
    presenterDay(row, fieldName) {
      return moment(TripModel.presenter(row, fieldName)).locale(this.currentLanguageCode).format("dddd");
    },
    presenterTime(row, fieldName) {
      // let now = moment().locale('en').format('YYYY/MM/DD');
      // let time = TripModel.presenter(row, fieldName)
      let n = row[fieldName]

      // console.log();
      // let date =  `${now} ${time}`;
      return moment(n,"YYYY-MM-DD'T'hh:mm:ss.SSS'Z'").locale(this.currentLanguageCode).format('hh:mm a')
    },
    presenterUser(row, fieldName, key) {
      const user = TripModel.presenter(row, fieldName);
      if (key == 'avatars' || key == 'avatar') {
        let avatar = null
        if (user && Array.isArray(user[key])) {
          avatar = user[key].length ? user[key][0].publicUrl : null
        } else if (user && user[key]) {
          avatar = user[key].publicUrl 
        }
        return avatar ? avatar : 'https://firebasestorage.googleapis.com/v0/b/puricom-beebe.appspot.com/o/public%2Fdefault%2Favatar.svg?alt=media&token=439fb6e6-815f-453e-9e3a-79dc0ab3d40c'
      }
      return user && user[key] ? user[key] : 'ـــ'; 
    },
    presenterClient(row, fieldName, key) {
      const val = TripModel.presenter(row, fieldName);
      return val && val[key] ? val[key] : 'ـــ'; 
    },
    presenterLocalization(data) {
      if (typeof data === 'object' && data !== null) {
        return data[this.currentLanguageCode] ? data[this.currentLanguageCode] : data['en']
        // if (this.currentLanguageCode in data) {
        //   return data[this.currentLanguageCode]
        // }
      }
      return  
    },
  },
};
