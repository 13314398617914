//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { i18n } from '@/vueI18n';

export default {
  name: 'app-trip-cancelation-reason',
  props: {
    cancelationReason: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      
    }
  },
  computed: {
    ...mapGetters({
      record: 'trip/view/record',
      loading: 'trip/view/loading',

      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
  },
  methods: {
    ...mapActions({
      // doFind: 'trip/view/doFind',
      // doFindByVuexfire: 'trip/view/doFindByVuexfire',
    }),
    i18n(key, args) {
      return i18n(key, args);
    },
  },
};
